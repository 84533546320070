/* eslint-disable no-console */
/* eslint indent: [1, 2, {"SwitchCase": 1}]*/

'use strict';

const App = {};

App.ready = (callback) => {
  if (document.readyState != 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
};

App.ready(() => {
  App.init();
});

App.init = function () {
  // console.log('App is ready!');
  this.initMobileNavigation();
  this.initCookieConsent();
};

App.initMobileNavigation = function () {
  const $button = document.getElementById('js-mobile-menu-toggle');
  const $menu = document.getElementById('js-mobile-menu');

  if (!$button || !$menu) return;

  $button.addEventListener(
    'click',
    function (e) {
      e.preventDefault();
      $menu.classList.toggle('hidden');
      $button.classList.toggle('active');
      document.body.style.overflowY =
        document.body.style.overflowY === 'hidden' ? 'visible' : 'hidden';
    },
    false
  );

  const alternateClosingTriggers = document.querySelectorAll('.js-close-menu');

  for (let i = 0; i < alternateClosingTriggers.length; i++) {
    alternateClosingTriggers[i].addEventListener(
      'click',
      function (e) {
        $menu.classList.add('hidden');
        $button.classList.remove('active');
        document.body.style.overflowY = 'visible';
      },
      false
    );
  }
};

App.initCookieConsent = function () {
  const $consentForm = document.getElementById('js-cookie-consent-form');
  if (!$consentForm) return;

  const $consentFormShow = document.getElementById('js-cookie-consent-show');
  if (
    $consentFormShow.classList.contains('ee:cookies_functionality') &&
    $consentFormShow.classList.contains('ee:cookies_performance') &&
    $consentFormShow.classList.contains('ee:cookies_targeting')
  ) {
    $consentFormShow.classList.add('hidden');
  }

  const $accept = document.getElementById('js-cookie-consent-accept');
  const $decline = document.getElementById('js-cookie-consent-decline');
  const $inputs = document.querySelectorAll('.js-cookie-consent-input');

  const submitForm = async function () {
    ////////// Jquery version
    // const formData = {
    //   ACT: $('input[name=ACT]').val(),
    //   RET: $('input[name=RET]').val(),
    //   consent_names: $('input[name=consent_names]').val(),
    //   site_id: $('input[name=site_id]').val(),
    //   csrf_token: $('input[name=csrf_token]').val(),
    //   'ee:cookies_functionality': $('input[name="ee:cookies_functionality"]').val(),
    //   'ee:cookies_performance': $('input[name="ee:cookies_performance"]').val(),
    //   'ee:cookies_targeting': $('input[name="ee:cookies_targeting"]').val(),
    // };
    // $.ajax({
    //   type: $consentForm.getAttribute('method'),
    //   url: $consentForm.getAttribute('action'),
    //   data: formData,
    //   dataType: 'json',
    //   encode: true,
    // }).done(function (data) {
    //   $consentFormShow.addClass('hidden');
    // });
    ////////////

    const response = await fetch($consentForm.action, {
      method: $consentForm.method,
      body: new URLSearchParams(new FormData($consentForm)),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (response.ok) {
      $consentFormShow.classList.add('hidden');
    }
  };

  $accept.addEventListener('click', function (e) {
    e.preventDefault();
    $inputs.forEach(function (input) {
      input.value = 'y';
    });
    submitForm();
  });

  $decline.addEventListener('click', function (e) {
    e.preventDefault();
    $inputs.forEach(function (input) {
      input.value = 'n';
    });
    submitForm();
  });
};
